<template>
  <button class="authorization-phone" @click="showPhoneAuthorizationForm">По номеру телефона</button>

  <FullscreenForm
      v-if="phoneAuthIsShown"
      header="Авторизация"
      @hide="phoneAuthIsShown=false"
  >
    <div v-if="errorText !== ''">{{errorText}}</div>
    <template v-if="!codeSentToPhone">
      Введите номер телефона
      <div>
        +7 <input v-model="phoneNumber" type="number" :disabled="apiInProgress"/>
      </div>
      <button @click="getCodeButtonClick" :disabled="apiInProgress || ! /^[0-9]{10}$/.test(phoneNumber.toString())">Получить код</button>
      После нажатия на кнопку на ваш номер поступит телефонный звонок. Введите последние 4 цифры номера телефона,
      с которого вы получили звонок, в качестве кода.

      <YandexSmartCaptcha
          ref="captcha"
          @token="captchaToken"
          :captcha-id="captchaId"
      />
    </template>

    <template v-if="codeSentToPhone && ! codeVerified">
      В ближайшее время на ваш номер телефона {{ phoneNumber }} поступит звонок. Введите последние 4 цифры номера,
      с которого происходит вызов, в поле ниже:

      <input v-model="verificationCode" type="number" maxlength="4" :disabled="apiInProgress"/>
      <button @click="verifyCode" :disabled="verificationCode.toString().length !== 4 || apiInProgress">Проверить код</button>
    </template>

    <template v-if="codeVerified">
      Ваш номер телефона успешно подтверждён. Введите своё имя для завершения регистрации:

      <input v-model="userName" maxlength="128" :disabled="apiInProgress"/>
      <button @click="register" :disabled="apiInProgress || userName.trim().length < 2">Войти</button>
    </template>

  </FullscreenForm>
</template>

<script>
import YandexSmartCaptcha from "@/components/quest/commonPageElements/YandexSmartCaptcha.vue";
import FullscreenForm from "@/components/quest/commonPageElements/FullscreenForm.vue";
import {apiCall} from "@/common-functions";

export default {
  components: {FullscreenForm, YandexSmartCaptcha},
  emits: ['authorizedSuccess'],
  data() {
    return {
      phoneAuthIsShown: false,
      phoneNumber: '',
      authSession: null,
      apiInProgress: false,
      codeSentToPhone: false,
      codeVerified: false,
      verificationCode: '',
      userName: '',
      errorText: '',
      timeToRetry: 0,
      captchaId: Math.random().toString()
    }
  },
  mounted() {
    this.resetPage();
    this.errorText = '';
  },
  methods: {
    showPhoneAuthorizationForm() {
      this.phoneAuthIsShown = true;
      this.apiInProgress = false;
      this.resetPage();
    },
    resetPage() {
      this.codeSentToPhone = false;
      this.codeVerified = false;
      this.captchaId = Math.random().toString();
      console.log('new captcha id', this.captchaId);
    },

    getCodeButtonClick() {
      this.apiInProgress = true;
      this.$refs.captcha.execute();
    },

    captchaToken(token) {
      if (! token) {
        this.apiInProgress = false;
        this.errorText = 'Ошибка прохождения капчи';
        this.resetPage();
      }
      this.errorText = '';
      apiCall(
          '/public/auth/phone/getCode',
          {
            method: 'POST',
            body: JSON.stringify({
              phoneNumber: this.phoneNumber,
              token
            }),
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            console.log(res);
            this.apiInProgress = false;
            this.codeSentToPhone = res.success;
            if (! res.success) {
              this.errorText = res.message;
              this.resetPage();
            }
            this.authSession = res.authSession;
            this.timeToRetry = res.timeToRetry ? res.timeToRetry : 0;
          });
    },

    verifyCode() {
      this.errorText = '';
      this.apiInProgress = false;
      apiCall(
          '/public/auth/phone/check',
          {
            method: 'POST',
            body: JSON.stringify({
              phoneNumber: this.phoneNumber,
              authSession: this.authSession,
              verificationCode: this.verificationCode
            }),
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            console.log(res);
            if (! res.success) {
              this.apiInProgress = false;
              this.errorText = res.message;
            } else {
              this.codeVerified = true;
            }
          });
    },

    register() {
      this.apiInProgress = true;
      this.$emit('authorizedSuccess', 'phone', {
        phoneNumber: this.phoneNumber,
        userName: this.userName,
        authSession: this.authSession
      });
    }
  }
}
</script>

<style scoped>
.authorization-phone {
  margin: 5px 20px;
}
</style>