<template>
  <button @click="showAuthorization">Авторизация</button>
  <div v-if="authorizationFrameIsShown" class="authorization-frame">
    <div class="header">
        <template v-if="header">
          {{header}}
        </template>
        <template v-else>
          Для прохождения квеста необходимо авторизоваться
        </template>
      <div class="close-container">
        <div class="close" @click="hideAuthorization"></div>
      </div>
    </div>
    <div class="main-frame">
      <div class="provider-list"
           :class="authEnabled? '' : 'grayscale'"
      >
        <div
            v-if="!authEnabled"
            class="disable-auth-div"
        >

        </div>
        <AuthorizationTelegram
            v-if="isAuthorized !== true && (!provider || provider === 'telegram')"
            :auth-enabled="authEnabled"
            @authorizedSuccess="gotAuthorizationData"
        />

        <AuthorizationVk
            v-if="isAuthorized !== true && (!provider || provider === 'vk')"
            :auth-enabled="authEnabled"
            @authorizedSuccess="gotAuthorizationData"
        />

        <AuthorizationPhone
            v-if="isAuthorized !== true && (!provider || provider === 'phone')"
            :auth-enabled="authEnabled"
            @authorizedSuccess="gotAuthorizationData"
        />

        <AuthorizationGoogle
            v-if="isAuthorized !== true && (!provider || provider === 'google')"
            :auth-enabled="authEnabled"
            @authorizedSuccess="gotAuthorizationData"
        />
      </div>

      <div class="policy">
        <input type="checkbox" id="privacy" v-model="authEnabled">
        <label for="privacy">
          Принимаю <a target="_blank" href="https://artquestwalk.ru/privacy-policy/">политику конфиденциальности</a>
        </label>
      </div>
    </div>



  </div>
</template>

<script>
import {apiCall, metrikaEvent} from '../../common-functions';
import AuthorizationTelegram from "./AuthorizationTelegram";
import AuthorizationVk from "./AuthorizationVk";
import AuthorizationPhone from "./AuthorizationPhone";
import AuthorizationGoogle from "./AuthorizationGoogle";

export default {
  components: {
    AuthorizationTelegram,
    AuthorizationVk,
    AuthorizationPhone,
    AuthorizationGoogle,
  },
  emits: ['authorizedSuccess', 'authorizationDataCallback'],
  props: {
    header: String,
    authPrefix: String,
    provider: String,
    privacyPolicy: String
  },
  data() {
    return {
      userData: null,
      isAuthorized: null,
      authorizationFrameIsShown: false,
      authEnabled: false
    };
  },
  methods: {
    gotAuthorizationData(provider, userData) {
      apiCall(
          '/public/auth/' + provider,
          {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            if (res.success) {
              window.localStorage.setItem(this.authPrefix + '_authToken', res.token);
              window.localStorage.setItem(this.authPrefix + '_validBefore', res.validBefore);
              window.localStorage.setItem(this.authPrefix + '_userData', JSON.stringify(userData));
              this.hideAuthorization();
              this.$emit('authorizedSuccess', userData);
              metrikaEvent('authorized-'+provider);
            }
          });
    },

    showAuthorization() {
      this.authorizationFrameIsShown = true;
    },
    hideAuthorization() {
      this.authorizationFrameIsShown = false;
    },
  },
  mounted() {
    const component = this;
    const authToken = window.localStorage.getItem(this.authPrefix + '_authToken');
    if (authToken) {
      apiCall(
          '/public/auth/' + 'checkToken' + '?token=' + authToken,
          {
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(res => {
            return res.json();
          })
          .then(res => {
            if (res.success) {
              window.localStorage.setItem(this.authPrefix + '_authToken', res.token);
              this.$emit('authorizedSuccess', window.localStorage.getItem(this.authPrefix + '_userData'));
              component.isAuthorized = true;
            } else {
              component.isAuthorized = false;
              window.localStorage.removeItem(this.authPrefix + '_authToken');
              window.localStorage.removeItem(this.authPrefix + '_authToken');
            }
          });
    }
  }
}
</script>

<style scoped>
  .authorization-frame {
    position: absolute;
    left: 0;
    right: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .header {
    background-color: #36ff00;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .provider-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .main-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .disable-auth-div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5000;
  }

  .close-container {
    position: absolute;
    right: 0;
    width: 40px;
  }
  .close {
    position: relative;
    width: 58px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .policy {
    padding: 20px;
  }

  label {
    font-family: Helvetica, Arial;
  }

  .grayscale {
    filter: grayscale(1);
  }

  @media (max-width: 375px) {
    button {
      font-size: 18px;
      height: 34px;
    }
    .content-footer {
      height: 55px;
    }
  }
</style>
