<template>
  <div class="authorization-tg"></div>
</template>

<script>
export default {
  emits: ['authorizedSuccess'],
  mounted() {
    const component = this;

    const telegramScript = document.createElement('script');
    telegramScript.setAttribute('type', 'text/javascript');
    telegramScript.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?21');
    telegramScript.setAttribute('data-telegram-login', process.env.VUE_APP_TELEGRAM_AUTH_BOT_USERNAME);
    telegramScript.setAttribute('data-size', 'large');
    telegramScript.setAttribute('data-onauth', 'onTelegramAuth(user)');
    telegramScript.setAttribute('data-request-access', 'write');
    document.querySelector('.authorization-tg').appendChild(telegramScript);

    window.onTelegramAuth = (user) => {
      console.log('Data from telegram:', user);
      component.$emit('authorizedSuccess', 'telegram', user);
    };
  }
}
</script>
