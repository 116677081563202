<template>
  <div :class="separatePage ? 'content' : 'fullscreen-form'">
    <h1 class="header">
      {{ header }}
      <div
          v-if="! separatePage"
          class="close-container"
      >
        <div class="close" @click="$emit('hide')"></div>
      </div>
    </h1>

    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['hide'],
  props: {
    separatePage: Boolean,
    header: String
  },
  data() {
    return {
    };
  },
  methods: {
  },

  mounted() {
  }
}
</script>

<style scoped>
.header {
  background-color: #36ff00;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-block-start: 0;
}
.close-container {
  position: absolute;
  right: 0;
  width: 40px;
}
.close {
  position: relative;
  width: 58px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

button {
  margin: 10px;
}

.content {
  font-family: Verdana, Arial;
  padding: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
}
</style>
