<template>
  <div id="vk_auth" class="vk-authorization"></div>
</template>

<script>
export default {
  emits: ['authorizedSuccess'],
  mounted() {
    const component = this;

    window.onVkAuth = (user) => {
      console.log('Data from vk:', user);
      component.$emit('authorizedSuccess', 'vk', user);
    };

    const vkScript = document.createElement('script');
    vkScript.type = 'text/javascript';
    vkScript.text = '  VK.init({\n' +
        '    apiId: ' + process.env.VUE_APP_VK_APP_ID +
        '  });' +
        'VK.Widgets.Auth(\'vk_auth\', {onAuth: window.onVkAuth});'
    document.querySelector('.vk-authorization').appendChild(vkScript);
  }
}
</script>
