<template>
  <div id="googleButton"></div>
</template>

<script>
export default {
  emits: ['authorizedSuccess'],
  props: {
    authEnabled: Boolean
  },
  mounted() {
    const component = this;

    const handleCredentialResponse = (response) => {
      console.log('Data from google:', response);
      component.$emit('authorizedSuccess', 'google', response);
    }

    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
      callback: handleCredentialResponse
    });

    window.google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        { theme: "outline", size: "large" }
    );

    if (this.authEnabled) {
      window.google.accounts.id.prompt(); // also display the One Tap dialog
    }
  },
  watch: {
    "authEnabled": function() {
      if (this.authEnabled) {
        window.google.accounts.id.prompt();
      }
    }
  }
}
</script>
