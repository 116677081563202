<template>
  <div id="captcha-container"></div>
  <div class="gather-info">
    Мы используем SmartCaptcha для защиты от роботов. SmartCaptcha обрабатывает ваши данные.
  </div>
</template>

<script>
export default {
  emits: ['token'],
  props: {
    captchaId: String
  },
  data() {
    return {
      widgetId: null,
      tokenChecked: false,
      token: null
    }
  },
  mounted() {
    this.createCaptchaWidget();
  },
  beforeUnmount() {
    this.destroyCaptchaWidget();
  },
  watch: {
    'captchaId': function() {
      this.destroyCaptchaWidget();
      this.createCaptchaWidget();
    }
  },
  methods: {
    createCaptchaWidget() {
      const component = this;

      const renderCaptchaWidget = () => {
        component.widgetId = window.smartCaptcha.render('captcha-container', {
          sitekey: process.env.VUE_APP_SMARTCAPTCHA_CLIENT_KEY,
          invisible: true,
          callback: component.checkCaptchaToken,
          hideShield: true
          // test: true
        });

        window.smartCaptcha.subscribe(component.widgetId, 'challenge-hidden', () => {
          this.$emit('token', null);
        });
      };

      if (! window.smartCaptcha) {
        const captchaOnLoadFunctionName = 'captchaOnloadFunction' + Math.round(Math.random() * 10000).toString();

        const captchaScript = document.createElement('script')
        captchaScript.setAttribute('src', 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=' + captchaOnLoadFunctionName)
        captchaScript.setAttribute('defer', 'defer')
        document.head.appendChild(captchaScript)

        window[captchaOnLoadFunctionName] = () => {
          if (!window.smartCaptcha) {
            return;
          }
          renderCaptchaWidget();
        }
      } else {
        renderCaptchaWidget();
      }
    },

    destroyCaptchaWidget() {
      if (this.widgetId) {
        window.smartCaptcha.destroy(this.widgetId);
        this.widgetId = null;
      }
    },

    checkCaptchaToken(token) {
      this.token = token;
      this.$emit('token', token);
    },

    execute() {
      if (this.widgetId !== null) {
        window.smartCaptcha.execute(this.widgetId);
      }
    }
  },
}
</script>

<style scoped>
.gather-info {
  margin-top: 10px;
}
</style>